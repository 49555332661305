<!-- 供应商需求详情 -->
<template>
  <div class="container">
    <div id="containerMap"></div>
    <div class="container_quotation">
      <div class="container_quotation_title">
        <span>{{ title }}</span>
      </div>
      <div class="container_quotation_form">
        <div :class="['container_quotation_form_item', quotationStatus == 1 ? 'disabled' : '']">
          <el-form ref="formRef" :model="form1" @submit="submitForm" :rules="rules">
            <el-form-item label="HS Code" prop="hscode">
              <el-input :disabled="quotationStatus == 1" v-model="form1.hscode" placeholder="Enter HS Code"></el-input>
            </el-form-item>
            <!-- <el-form-item
              label="Official Order Delivery Time"
              prop="deliveryTime"
            >
              <el-input
                :disabled="quotationStatus == 1"
                v-model="form1.deliveryTime"
                placeholder="Enter Delivery Time"
              ></el-input>
            </el-form-item> -->

            <el-form-item label="Estimated Time of Delivery" prop="productionDuration">
              <el-date-picker :disabled="quotationStatus == 1" v-model="form1.productionDuration" type="date" placeholder="Delivery Time Requirment" format="YYYY-MM-DD" value-format="YYYY-MM-DD"></el-date-picker>
            </el-form-item>

            <el-form-item label="Sample Charge" prop="sampleUnitPrice">
              <el-input :disabled="quotationStatus == 1" v-model="form1.sampleUnitPrice" placeholder="Enter Sample Charge" type="number"></el-input>
            </el-form-item>
            <el-form-item label="Sample Lead Time" prop="sampleGenerationPeriod">
              <el-date-picker :disabled="quotationStatus == 1" v-model="form1.sampleGenerationPeriod" type="date" placeholder="Sample Leadtime Requirment" format="YYYY-MM-DD" value-format="YYYY-MM-DD"></el-date-picker>
              <!-- <el-input
                :disabled="quotationStatus == 1"
                v-model="form1.sampleLeadtime"
                placeholder="Enter Sample Leadtime"
              ></el-input> -->
            </el-form-item>
            <el-form-item label="Pcs/Ctn" prop="pcsCtnSingle">
              <el-input :disabled="quotationStatus == 1" v-model="form1.pcsCtnSingle" placeholder="Enter Pcs/Ctn" type="number"></el-input>
            </el-form-item>

            <el-form-item label="Carton Quantity" prop="ctnNum">
              <el-input :disabled="quotationStatus == 1" v-model="form1.ctnNum" placeholder="Enter numbers only" type="number" onkeyup="this.value= this.value.match(/^\+?[1-9]\d*$/) ? this.value.match(/^\+?[1-9]\d*$/)[0] : ''"></el-input>
            </el-form-item>

            <el-form-item label="Carton Size (mm)" prop="ctnSizeSingle">
              <el-input :disabled="quotationStatus == 1" v-model="form1.ctnSizeSingle" placeholder="L * W * H"></el-input>
            </el-form-item>
            <el-form-item label="CBM/Ctn (m³)" prop="cbmCtnSingle">
              <el-input :disabled="quotationStatus == 1" v-model="form1.cbmCtnSingle" placeholder="Enter numbers only" type="number"></el-input>
            </el-form-item>
            <el-form-item label="Weight/Carton (kg)" prop="weightCtnSingle">
              <el-input :disabled="quotationStatus == 1" v-model="form1.weightCtnSingle" placeholder="Enter numbers only" type="number"></el-input>
            </el-form-item>
            <el-form-item label="Total CBM" prop="totalCbm">
              <el-input :disabled="quotationStatus == 1" v-model="form1.totalCbm" placeholder="Enter numbers only" @click="calculate" type="number"></el-input>
            </el-form-item>
            <el-form-item label="Gross Weight (kg)" prop="totalGrossWeight">
              <el-input :disabled="quotationStatus == 1" v-model="form1.totalGrossWeight" placeholder="Enter numbers only" type="number"></el-input>
            </el-form-item>
            <el-form-item label="FOB/CIF" prop="fobcif">
              <el-select :disabled="quotationStatus == 1" v-model="form1.fobcif" placeholder="Select fobcif">
                <el-option v-for="(item, index) in fobcifs" :key="index" :label="item" :value="item"></el-option>
              </el-select>
            </el-form-item>
            <!-- Currency 选择 -->
            <el-form-item label="Currency" prop="currency">
              <el-select :disabled="quotationStatus == 1" v-model="form1.currency" placeholder="Select Currency">
                <el-option label="EUR" value="EUR"></el-option>
                <el-option label="USD" value="USD"></el-option>
                <el-option label="CNY" value="CNY"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="Unit Price" prop="unitPrice">
              <el-input :disabled="quotationStatus == 1" v-model="form1.unitPrice" placeholder="Enter Unit Price" type="number"></el-input>
            </el-form-item>
            <el-form-item label="Special Charges" prop="specialCharges">
              <!--							<el-input :disabled="quotationStatus == 1" v-model="form1.specialCharges" placeholder="Enter Special charges"></el-input>-->
              <el-select :disabled="quotationStatus == 1" v-model="form1.specialCharges" placeholder="Enter Special charges">
                <el-option v-for="(item, index) in specialChargeOptions" :key="index" :label="item" :value="item"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="Charge Amount" prop="chargeAmount" v-if="form1.specialCharges != 'None' && form1.specialCharges != ''">
              <el-input :disabled="quotationStatus == 1" v-model="form1.chargeAmount" placeholder="Enter numbers only" type="number"></el-input>
            </el-form-item>

            <div class="el-form-line2">Material Usage</div>
            <div class="add_material">
              <el-button  type="info" plain @click="addData">Add Material</el-button>
            </div>
            <div v-for="(child,childIndex) in childList" :key="childIndex" class="materialList" style="width:800px;display: flex;align-items: center;">
              <el-form-item label="Material" prop="materialId" >
                <el-select :disabled="quotationStatus == 1" v-model="child.materialId" placeholder="Select Material">
                  <el-option v-for="(item, index) in materials" :key="index" :label="item.content" :value="item.id"></el-option>
                </el-select>
              </el-form-item>
              <!-- Material Usage -->
              <el-form-item label="Total Material Usage (g)" prop="materialUsage" style="margin-left: 20px">
                <el-input :disabled="quotationStatus == 1" v-model="child.materialUsage" placeholder="Enter numbers only" type="number"></el-input>

                <!-- <el-select
                  :disabled="quotationStatus == 1"
                  v-model="form1.materialUsage"
                  placeholder="Select Material Usage"
                >
                  <el-option
                    v-for="(item, index) in materialUsages"
                    :key="index"
                    :label="item"
                    :value="item"
                  ></el-option>
                </el-select> -->
              </el-form-item>
              <el-button style="margin-left: 10px"  type="info" plain @click="delData(childIndex)">Delete Material</el-button>
            </div>

            <div class="el-form-line2">Energy Usage</div>
            <!-- Mode of production -->
            <el-form-item label="Type of Production" prop="productionMode">
              <el-select :disabled="quotationStatus == 1" v-model="form1.productionMode" placeholder="Select Mode of Production">
                <el-option v-for="(item, index) in productModeList" :key="index" :label="item.catalog2" :value="item.id"></el-option>
              </el-select>
            </el-form-item>

            <!-- Energy used -->
            <el-form-item label="Total Energy Usage (kWh)" prop="energyuse">
              <el-input :disabled="quotationStatus == 1" v-model="form1.energyuse" placeholder="Enter numbers only" type="number"></el-input>
            </el-form-item>

            <div class="el-form-line2">Carton Usage</div>

            <el-form-item label="Total Carton Gross Weight (g)" prop="grossCtnWeight">
              <el-input :disabled="quotationStatus == 1" v-model="form1.grossCtnWeight" placeholder="Enter numbers only" type="number"></el-input>
            </el-form-item>

            <div class="el-form-line2">Material <img src="../../assets/images/supplier/quotation/arrow-right.png" alt=""><span>Factory</span>
            </div>

            <!-- 原料地点 -->
            <!-- Raw material location 输入-->
            <el-form-item label="Raw Material Origin" prop="rawMaterialLocation">
              <el-input :disabled="quotationStatus == 1" v-model="form1.rawMaterialLocation" placeholder="Enter Raw material location" @focus="openAmap('material')"></el-input>
            </el-form-item>

            <!-- Factory Location -->
            <el-form-item label="Factory Location" prop="factoryLocation">
              <el-input :disabled="quotationStatus == 1" v-model="form1.factoryLocation" placeholder="Enter Factory Location" @focus="openAmap('Factory')"></el-input>
            </el-form-item>

            <el-form-item label="Distance(km)" prop="materialDistance">
              <el-input disabled v-model="form1.materialDistance"></el-input>
            </el-form-item>
            <!-- Mode of transport -->
            <el-form-item label="Mode of transport" prop="materialTransportationType" v-if="1 > 2">
              <!--							<el-select :disabled="quotationStatus == 1" v-model="form1.materialTransportationType" placeholder="Select Mode of Transport">-->
              <!--								<el-option v-for="(item, index) in modeOfTransports" :key="index" :label="item.type" :value="item.id"></el-option>-->
              <!--							</el-select>-->
              <el-input disabled v-model="form1.materialTransportationType"></el-input>
            </el-form-item>

            <div class="el-form-line2">Factory <img src="../../assets/images/supplier/quotation/arrow-right.png" alt=""><span>Port of Departure</span></div>

            <!-- Port of departure -->
            <el-form-item label="Port of Departure" prop="port">
              <el-select :disabled="quotationStatus == 1" v-model="form1.port" placeholder="Select Port of Departure" @change="portChange">
                <el-option v-for="item in Object.keys(portLocations)" :key="item" :label="item" :value="item"></el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="Distance(km)" prop="distance">
              <el-input disabled v-model="form1.distance"></el-input>
            </el-form-item>

            <!-- Mode of transport (Duplicate?) -->
            <el-form-item label="Mode of transport" prop="transportationType" v-if="1 > 2">
              <!--							<el-select :disabled="quotationStatus == 1" v-model="form1.transportationType" placeholder="Select Mode of Transport">-->
              <!--								<el-option v-for="(item, index) in modeOfTransports2" :key="index" :label="item.type" :value="item.id"></el-option>-->
              <!--							</el-select>-->
              <el-input disabled v-model="form1.transportationType"></el-input>
            </el-form-item>
            <div class="el-form-line2">International Transportation</div>
            <el-form-item label="Type of Production" prop="internationalTransportationType">
              <el-select :disabled="quotationStatus == 1" v-model="form1.internationalTransportationType" placeholder="Select Mode of Transport">
                <el-option v-for="(item, index) in modeOfTransports2" :key="index" :label="item.type" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
            <div class="el-form-line2" v-if="orderId != null && orderId !== undefined">Requirement</div>
            <el-form-item label="Formal Num" prop="formalNum" v-if="orderId != null && orderId !== undefined">
              <el-input v-model="form1.formalNum" type="number" placeholder="Enter Formal Num" onkeyup="this.value= this.value.match(/^\+?[1-9]\d*$/) ? this.value.match(/^\+?[1-9]\d*$/)[0] : ''"></el-input>
            </el-form-item>

            <el-form-item class="el-btn" v-if="quotationStatus == 0">
              <el-button type="primary" @click="submitForm(formRef)">Send to customer</el-button>
            </el-form-item>
            <!-- 已报价 -->
            <div class="container_quotation_form_item_quotation-status" v-if="quotationStatus == 1 && (orderId == null || orderId === undefined)">
              <img src="../../assets/images/supplier/check-circle.png" alt="">
              Quotation Sent
            </div>
            <el-form-item class="el-btn" v-if="quotationStatus == 1 && (orderId != null && orderId !== undefined)">
              <el-button type="primary" @click="submitForm(formRef)">Send to suppliers</el-button>
            </el-form-item>
          </el-form>

        </div>
      </div>
    </div>

    <Amap ref="refAmap" v-on:customEvent="amapCustomEvent" v-if="showAMap" />
    <Foot />
  </div>
</template>

<script setup name="Quotation">
import { ref, reactive } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import Foot from '../../components/Foot.vue'
import Amap from './dialog/Amap.vue'
import AMapLoader from '@amap/amap-jsapi-loader'
import { shallowRef } from '@vue/reactivity'
import { addQuotation, getQuotationDetail } from '@/api/quotation'
import { ElMessage } from 'element-plus'
import { getTransportType } from '@/api/transport'
import { getMaterialList } from '@/api/user'
import { getProductModeList } from '@/api/productmode'
import { signFormalOrder } from '@/api/order'

const map = shallowRef(null)
// "key": "f4cd24293f5594ffcda56bad70dcabb6"
// 添加高德安全密钥
window._AMapSecurityConfig = {
  securityJsCode: '5ca65c74bfbcc9922df08400c8dba2d5'
}

const childList = ref([
  {
    materialId: '',
    materialUsage: ''
  }
])
const childShow = ref(false)
const addData = () => {
  childShow.value = true
  childList.value.push({
    materialId: '',
    materialUsage: ''
  })
}

const delData = (index) => {
  if (childList.value.length === 1) {
    childShow.value = false
    ElMessage({
      message: 'Please fill in at least one!',
      type: 'error'
    })
    return false
  }
  childList.value.splice(index, 1)
}

// 报价状态 0:未报价 1:已报价
const quotationStatus = ref(0)
const productModeList = ref([])
const title = ref('Make a quotation')
const fobcifs = ref(['FOB', 'CIF'])

// 获取路由参数
const router = useRouter()
const route = useRoute()
const requirementId = route.query.id
// console.log('需求详情传递过来的id', requirementId)
const quotationId = route.query.qid
// console.log('报价单传递过来的id', quotationId)
const orderId = route.query.orderId
if (orderId !== null && orderId !== undefined) {
  title.value = 'Quotation Detail'
}

const consave = ref(true)

// console.log('首页样品订单传递过来的样品订单id', orderId)
// console.log('首页样品订单传递过来的报价单id', quotationId)
const form1 = ref({
  requirementId: requirementId,
  hscode: '',
  deliveryTime: '',
  sampleCharge: '',
  sampleLeadtime: '',
  pcsPerCtn: '',
  ctnSizeSingle: '',
  cbmCtnSingle: '',
  weightCtnSingle: '',
  totalCbm: '',
  totalGrossWeight: '',
  UnitPrice: '',
  specialCharges: '',
  materialId: '',
  materialUsage: '',
  productionMode: '',
  energyuse: '',
  materialDistance: '',
  materialTransportationType: '3',
  port: '',
  distance: '',
  transportationType: '3',
  rawMaterialLocation: '',
  factoryLocation: '',
  internationalTransportationType: '',
  ctnNum: '',
  grossCtnWeight: '',
  fobcif: '',
  productionDuration: '',
  sampleUnitPrice: '',
  sampleGenerationPeriod: '',
  pcsCtnSingle: '',
  unitPrice: '',
  chargeAmount: '',
  formalNum: '',
  currency: '',
  materialList: []
})
const state = ref('')
const formRef = ref()

// 验证表单不为空
const validate = (rule, value, callback) => {
  if (value === '') {
    callback(new Error('Required'))
    // console.log(rule.field)
  } else {
    callback()
  }
}

// 需要验证的表单
const rules = reactive({
  hscode: [{ validator: validate, required: true, trigger: 'blur' }],
  pcsCtnSingle: [{ validator: validate, required: true, trigger: 'blur' }],
  ctnNum: [{ validator: validate, required: true, trigger: 'blur' }],
  totalGrossWeight: [{ validator: validate, required: true, trigger: 'blur' }],
  ctnSizeSingle: [{ validator: validate, required: true, trigger: 'blur' }],
  cbmCtnSingle: [{ validator: validate, required: true, trigger: 'blur' }],
  weightCtnSingle: [{ validator: validate, required: true, trigger: 'blur' }],
  fobcif: [{ validator: validate, required: true, trigger: 'change' }],
  unitPrice: [{ validator: validate, required: true, trigger: 'blur' }],
 /* materialId: [{ validator: validate, required: true, trigger: 'change' }],
  materialUsage: [{ validator: validate, required: true, trigger: 'blur' }],*/
  productionMode: [{ validator: validate, required: true, trigger: 'change' }],
  energyuse: [{ validator: validate, required: true, trigger: 'blur' }],
  rawMaterialLocation: [{ validator: validate, required: true, trigger: 'submit' }],
  factoryLocation: [{ validator: validate, required: true, trigger: 'submit' }],
  // materialDistance: [{ validator: validate, required: true, trigger: 'blur', message: 'Required input the Distance ' }],
  materialTransportationType: [{ validator: validate, required: true, trigger: 'blur' }],
  port: [{ validator: validate, required: true, trigger: 'change' }],
  transportationType: [{ validator: validate, required: true, trigger: 'change' }],
  internationalTransportationType: [{ validator: validate, required: true, trigger: 'change' }],
  grossCtnWeight: [{ validator: validate, required: true, trigger: 'blur' }],
  currency: [{ validator: validate, required: true, trigger: 'change' }]
})

// 订单状态变更
const stateChange = () => {
  console.log('订单状态变更', state.value)
}

const getQuotationDetails = async () => {
  const result = await getQuotationDetail(quotationId)
  if (result.code === 200) {
    form1.value = result.data
    quotationStatus.value = 1
  }
}
if (quotationId != null && quotationId !== undefined) {
  getQuotationDetails()
}

// 原料的经纬度
const materialLng = ref([])
// 工厂的经纬度
const factoryLng = ref([])

const materials = ref([])

// Special charges
const specialChargeOptions = ref(['Edition Fee', 'Mold Opening Fee', 'None', 'Other'])

// 获取材质列表
const getMaterialsList = async () => {
  const res = await getMaterialList()
  // console.log('materials===', res)
  if (res.code === 200) {
    materials.value = res.data
  }
}
getMaterialsList()

const getProductModeLists = async () => {
  const res = await getProductModeList()
  if (res.code === 200) {
    productModeList.value = res.data
  }
}

getProductModeLists()
const transTypes = ref([])
const getTransTypes = async () => {
  const result = await getTransportType()
  // console.log('results===', result)
  if (result.code === 200) {
    transTypes.value = result.data
  }
}
getTransTypes()

// const materialUsages = ref(['Material A', 'Material B', 'Material C'])
const modeOfProductions = ref(['Production A', 'Production B', 'Production C'])
const energyUseds = ref(['Energy A', 'Energy B', 'Energy C'])
const modeOfTransports = transTypes
const modeOfTransports2 = transTypes

// 上面是表单数据

const submitForm = (formEl) => {
  if (!formEl) return
  formEl.validate(async (valid) => {
    if (valid) {
      // console.log('submit!')
      form1.value.materialList = childList.value
      console.log(form1.value)
      if (form1.value.materialList.length === 0) {
        ElMessage({
          message: 'Please enter a material!',
          type: 'error'
        })
        return
      }
      if (form1.value.specialCharges != null && form1.value.specialCharges !== 'None' && form1.value.specialCharges !== '') {
        if (!form1.value.chargeAmount || !form1.value.chargeAmount === '') {
          ElMessage({
            message: 'Please enter a charge amount!',
            type: 'error'
          })
          return
        }
      }
      if (orderId !== null && orderId !== undefined) {
        if (!form1.value.formalNum) {
          ElMessage({
            message: 'Please enter a Formal Num!',
            type: 'error'
          })
          return
        }
        // 样品单生成正式单
        form1.value.orderId = orderId
        const result = await signFormalOrder(form1.value)
        ElMessage({
          message: 'Congrats, sign formal order success!',
          type: 'success'
        })
        if (result.code === 200) {
          router.back()
        }
        console.log('result==', result)
      } else {
        if (!consave.value) {
          return
        }
        consave.value = false
        const result = await addQuotation(form1.value)
        ElMessage({
          message: 'Congrats, add quotation success!',
          type: 'success'
        })
        if (result.code === 200) {
          consave.value = true
          router.back()
        }
        console.log('result==', result)
      }
    } else {
      console.log('error submit!')
      return false
    }
  })
}
// 接收是否关闭地图弹窗
const showAMap = ref(false)
// 记录点击的哪个按钮
const whichBtn = ref('')

const refAmap = ref(null)
const openAmap = (val) => {
  whichBtn.value = val
  // console.log('openAmap', val)
  showAMap.value = true
  setTimeout(() => {
    refAmap.value.setPlaceholder(val)
  }, 100)
}
const amapCustomEvent = (data) => {
  showAMap.value = false
  // console.log('amapCustomEvent，弹窗数据', data.address.value, data.location.value)

  // 原料地点
  if (data.address.value !== '请点击地图选择具体地点' && whichBtn.value === 'material') {
    form1.value.rawMaterialLocation = data.address
    materialLng.value = data.location.value
    localtionChange()
  } else if (data.address.value !== '请点击地图选择具体地点' && whichBtn.value === 'Factory') {
    form1.value.factoryLocation = data.address
    factoryLng.value = data.location.value
    localtionChange()
  }
}

const portLocations = ref({
  'PORT OF SHANGHAI': [121.471674, 30.841345],
  'PORT OF SHENZHEN': [113.838832, 22.485499],
  'PORT OF TIANJIN': [117.728848, 38.994634],
  'PORT OF NINGBO': [121.874531, 29.635993],
  'PORT OF QINGDAO': [120.354558, 36.064012],
  'PORT OF GUANGZHOU': [113.515403, 22.922798],
  'PORT OF XIANGGANG': [114.182386, 22.306274],
  'PORT OF FUZHOU': [119.28755, 26.056793],
  'PORT OF XIAMEN': [118.086166, 24.453685],
  'PORT OF DALLAN': [121.639284, 38.916666],
  'PORT OF NANJING': [118.871443, 31.968585]
})
const portChange = (val) => {
  // console.log('选择了港口', val)
  // console.log('portChange', val)

  if (factoryLng.value.length === 0) {

  } else {
    const realval = portLocations.value[val]
    initMap(factoryLng.value, realval).then(distance => {
      console.log('获取到工厂到港口的距离：' + distance + 'KM')
      form1.value.distance = distance
    }).catch(error => {
      console.log('获取距离失败：' + error)
    })
  }
}

// 计算原料到工厂的距离
const localtionChange = () => {
  console.log('选择了地点', materialLng.value, factoryLng.value)
  if (materialLng.value.length === 0 || factoryLng.value.length === 0) {

  } else {
    initMap(materialLng.value, factoryLng.value).then(distance => {
      console.log('获取到原料到工厂的距离：' + distance + 'KM')
      form1.value.materialDistance = distance
    }).catch(error => {
      console.log('获取距离失败：' + error)
    })
  }
}

// 计算距离
const initMap = (start, end) => {
  return new Promise((resolve, reject) => {
    AMapLoader.load({
      key: 'f4cd24293f5594ffcda56bad70dcabb6',
      version: '2.0',
      plugins: ['AMap.Driving']
    }).then((AMap) => {
      map.value = new AMap.Map('containerMap', {
        resizeEnable: true,
        center: [116.397428, 39.90923], // 地图中心点
        zoom: 5 // 地图显示的缩放级别
      })

      var driving = new AMap.Driving({
        map: map.value
      })
      driving.search(new AMap.LngLat(start[0], start[1]), new AMap.LngLat(end[0], end[1]), function (status, result) {
        if (status === 'complete') {
          // console.log('获取数据成功');
          const distance = result.routes[0].distance / 1000
          // console.log('距离：' + distance + 'KM')
          resolve(distance)
        } else {
          console.log('获取数据失败：' + result)
          reject(result)
        }
      })
    }).catch(e => {
      console.log(e)
      reject(e)
    })
  })
}

const calculate = () => {
  if (!form1.value.ctnNum || form1.value.ctnNum === '') {
    form1.value.totalCbm = null
    return
  }
  if (!form1.value.cbmCtnSingle || form1.value.cbmCtnSingle === '') {
    form1.value.totalCbm = null
    return
  }
  form1.value.totalCbm = form1.value.ctnNum * form1.value.cbmCtnSingle
}

</script>

<style lang="scss" scoped>
.container {
  line-height: 1;
  text-align: left;

  position: relative;
  width: 100%;
  // height: 100vh;
  display: flex;
  flex-direction: column;

  &_quotation {
    width: 1600px;
    margin: 50px auto 200px;
    border-radius: 8px;
    overflow: hidden;
    background-color: rgba(255, 255, 255, 1);
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);

    &_title {
      width: 100%;
      font-size: 20px;
      font-weight: 700;
      text-align: center;
      color: rgba(39, 174, 96, 1);
      background-color: rgba(39, 174, 96, 0.04);
      padding: 40px 0;
      // display: flex;
      // align-items: center;
      // justify-content: space-between;
      position: relative;

      span {
        margin-left: 40px;
      }
    }

    &_form {
      margin-top: 40px;

      &_item {
        ::v-deep .el-form {
          width: 730px;
          margin: 0 auto;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;

          .el-form-item {
            margin-bottom: 30px;
            width: 350px;
            display: flex;
            flex-direction: column;

            .el-date-editor {
              width: 340px;
              justify-content: flex-start;
              font-size: 14px;
              line-height: 1;
              height: 14px;
              margin-bottom: 10px;
              color: rgba(24, 24, 24, 1);
              font-weight: 300;
            }

            .el-form-item__label {
              width: 340px;
              justify-content: flex-start;
              font-size: 14px;
              line-height: 1;
              height: 14px;
              margin-bottom: 10px;
              color: rgba(24, 24, 24, 1);
              font-weight: 300;
            }

            .el-form-item__content {
              .el-input {
                height: 46px;

                .el-input__wrapper {
                  padding: 0px 20px;
                  box-shadow: none;
                  background-color: rgba(249, 249, 249, 1);

                  .el-input__inner {
                    color: rgba(24, 24, 24, 1);
                    font-size: 16px;

                    &::placeholder {
                      color: rgba(173, 173, 173, 0.6);
                    }
                  }
                }

                .is-focus {
                  box-shadow: 0px 0px 10px 0px rgba(39, 174, 96, 0.1);
                  border: 1px solid rgba(39, 174, 96, 1);
                  box-sizing: border-box;
                }
              }

              .el-select {
                width: 350px;

                .el-input__wrapper.is-focus {
                  box-shadow: 0px 0px 10px 0px rgba(39, 174, 96, 0.1) !important;
                  border: 1px solid rgba(39, 174, 96, 1);
                  box-sizing: border-box;
                }

                .is-focus {
                  box-shadow: 0px 0px 10px 0px rgba(39, 174, 96, 0.1);
                  border: 1px solid rgba(39, 174, 96, 1);
                  box-sizing: border-box;
                }
              }
            }

            .el-button {
              border-radius: 4px;
              background-color: rgba(39, 174, 96, 1);
              box-shadow: none;
              border: none;
              font-size: 14px;
              line-height: 14px;
              font-weight: 700;
              padding: 27px 30px;
            }

            &:last-child {
              width: 1200px;
              justify-content: center;
              align-items: center;
              margin: 70px auto 80px;
            }
          }
        }

        .el-form-line {
          width: 730px;
          margin: 20px auto 50px;
          height: 1px;
          border-bottom: 1px dashed rgba(39, 174, 96, 0.5);
        }

        .el-form-line2 {
          width: 730px;
          margin: 20px auto 50px;
          font-size: 14px;
          line-height: 1;
          color: rgba(36, 89, 2, 1);
          display: flex;
          align-items: center;
          justify-content: flex-start;
          white-space: nowrap;

          &::after {
            content: "";
            display: inline-block;
            width: 100%;
            height: 1px;
            border-bottom: 1px dashed rgba(173, 173, 173, 0.6);
            margin-left: 20px;
          }

          img {
            width: 14px;
            margin: 0 15px;
          }

          span {
            padding-right: 30px;
            white-space: nowrap;
          }
        }
        .add_material{
          width: 730px;
          margin-bottom: 20px;
          font-size: 12px;
          line-height: 1;
          color: rgba(36, 89, 2, 1);
          display: flex;
          align-items: center;
          justify-content: flex-start;
          white-space: nowrap;
        }
        &_quotation-status {
          width: 1200px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 50px auto;
          font-size: 14px;
          font-weight: 700;
          line-height: 1;
          color: rgba(39, 174, 96, 1);

          img {
            width: 14px;
            height: 14px;
            margin-right: 5px;
          }
        }
      }
    }

    // 输入框禁用样式
    .disabled {
      ::v-deep .el-form {
        .el-form-item {
          .el-form-item__label {
            color: rgba(173, 173, 173, 1);
          }
        }
      }
    }
  }
}
</style>
